import React, { useState, memo } from 'react';
import PropTypes from 'prop-types';
import { Box, Form, Text, TextInput, Button } from 'grommet';
import { Formik, Field, ErrorMessage } from 'formik';
import 'isomorphic-fetch';

import Loading from '../Loading';

const FormRestockNotification = ({
  variantId,
  variantTitle,
  productTitle,
  isSample,
  formStyle,
  onSuccess,
}) => {
  const [completed, setCompleted] = useState(false);
  return (
    <Formik
      initialValues={{
        email: '',
        variantId,
      }}
      validate={(values) => {
        const errors = {};
        if (!values['email'] || values['email'] === '') {
          errors['email'] = 'Required';
        } else if (
          !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values['email'])
        ) {
          errors['email'] = 'Invalid email address';
        }
        return errors;
      }}
      onSubmit={async (values, { setSubmitting, setFieldError }) => {
        setSubmitting(true);
        try {
          const response = await fetch(
            'https://backdrop-api.herokuapp.com/shopify/restock-notifications',
            {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                email: values.email,
                variantId: values.variantId,
              }),
            }
          );
          const json = await response.json();
          setSubmitting(false);
          setCompleted(true);
          if (onSuccess) {
            onSuccess(json);
          }
        } catch (e) {
          setSubmitting(false);
          setFieldError(
            'email',
            'Something went wrong. Please contact hello@backdrophome.com'
          );
        }
      }}
    >
      {({ isSubmitting, handleSubmit, isValid, handleChange, handleBlur }) => {
        return (
          <Box fill className="container">
            <Form
              id="digital_gift_card_form"
              onSubmit={handleSubmit}
              fill
              style={formStyle}
            >
              {completed ? (
                <Box pad="large" flex={true} justify="center">
                  <Text align="center" textAlign="center">
                    We&apos;ll send you an email when{' '}
                    {isSample
                      ? `12"x12" Sample in ${productTitle}`
                      : `${productTitle} ${variantTitle}`}{' '}
                    is back in stock
                  </Text>
                </Box>
              ) : (
                <>
                  <Box pad="large" gap="small" flex={true} justify="center">
                    <Text weight={500} textAlign="center">
                      Get notified when {isSample ? '12"x12" Sample in ' : ''}
                      {productTitle} is back in stock
                    </Text>
                    <Box fill="horizontal">
                      <Box flex={true} gap={'small'}>
                        <Box className="line-item-property__field">
                          <Field
                            name="email"
                            placeholder="Email"
                            id="email"
                            component={TextInput}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          <Field
                            name="variantId"
                            type="hidden"
                            id="variantId"
                          />
                          <ErrorMessage name="email">
                            {(msg) => (
                              <Text
                                color="status-error"
                                style={{ textAlign: 'left' }}
                              >
                                {msg}
                              </Text>
                            )}
                          </ErrorMessage>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                  <Box>
                    <Button
                      primary
                      type="submit"
                      onClick={handleSubmit}
                      disabled={isSubmitting || !isValid}
                      label="Notify Me"
                      icon={isSubmitting ? <Loading /> : null}
                      style={{ padding: '1.6em 20px' }}
                      reverse
                      fill
                    />
                  </Box>
                </>
              )}
            </Form>
          </Box>
        );
      }}
    </Formik>
  );
};

FormRestockNotification.propTypes = {
  variantId: PropTypes.string.isRequired,
  productTitle: PropTypes.string.isRequired,
  variantTitle: PropTypes.string.isRequired,
  isSample: PropTypes.bool,
  formStyle: PropTypes.object,
  onSuccess: PropTypes.func,
};

export default memo(FormRestockNotification);
